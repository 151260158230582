/*
 *
 * MarketPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import { ActionTypes } from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const applySearch = (data: any) => action(ActionTypes.APPLY_SEARCH, data);

export const products = createAsyncAction(
  ActionTypes.FETCH_PRODUCTS_REQUEST,
  ActionTypes.FETCH_PRODUCTS_SUCCESS,
  ActionTypes.FETCH_PRODUCTS_FAILURE,
)<void, {}, Error>();
