/*
 * MarketPage Messages
 *
 * This contains all the text for the MarketPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MarketPage';

export default defineMessages({
  mart: {
    id: `${scope}.mart`,
    defaultMessage: 'Chợ',
  },
  addToCart: {
    id: `${scope}.addToCart`,
    defaultMessage: 'Thêm vào giỏ',
  },
  notFound: {
    id: `${scope}.notFound`,
    defaultMessage: 'Không tìm thấy',
  },
  notFoundDescription: {
    id: `${scope}.notFoundDescription`,
    defaultMessage: 'Chúng tôi không tìm thấy sản phẩm nào',
  },
});
