/**
 *
 * MarketPage
 *
 */

import { Col, Pagination, Row } from 'antd';
import { Dispatch, compose } from 'redux';
import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  selectAddedProducts,
  selectAddingProducts,
  selectSelectedCategory,
  selectUserPermissions,
  selectBreadCrumbDataMarkup,
} from 'containers/MainLayout/selectors';

import { FormattedMessage } from 'react-intl';
import MarketItem from 'components/MarketItem/MarketItem';
import PageHeader from 'components/PageHeader/PageHeader';
import { ContainerState, RootState } from './types';
import { RouteComponentProps } from 'react-router-dom';
import { applySearch } from './actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import selectMarketPage from './selectors';
import { setFavorite, setProductNote } from 'containers/MainLayout/actions';
import styled from 'styled-components';
import ModalNote from 'components/Checkout/ModalNote';
import media from 'utils/mediaStyle';
import renderCategoryName from 'utils/renderCategoryName';
import { Category, TrackingAddToCartAddFromType, TrackingProductImpressionType as TrackingType } from 'types/schema';
import { isRecommendationTag } from 'utils/utilities';
import RenderMetadataMarket, { IMetadataKeyData } from 'components/RenderMetadataMarket';
import { isEmpty } from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import { push } from 'connected-react-router';
import { generateSlugFromQueryName } from 'utils/urls/categoriesPathSegment';
import PaginationLink from 'components/PaginationLink';
import keywordData from '../../../sitemap/keywordData.js';

interface ITrackingFrom {
  productImpression: TrackingType;
  addToCart: TrackingAddToCartAddFromType;
}

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  color: #475a5f;
  font-size: 16px;
  margin: 15px 0 6px 0;
`;

const Content = styled.p`
  color: #b3b9c7;
  font-size: 14px;
`;

const ProductListRow = styled(Row)`
  &.ant-row {
    display: flex;
    flex-wrap: wrap;
  }

  > .ant-col {
    &:not(:last-child) {
      border-bottom: 1px solid #dce0e9;
      ${media.md`
        border-bottom: none;
      `};
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (min-width: 1200px) {
    .ant-col-xl-5 {
      width: 20%;
    }
  }
`;

const ProductListContainer = styled.div`
  min-height: calc(100vh - 246px);
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 12px;
  ${media.md`
    padding: 24px;
    min-height: calc(100vh - 155px);
  `};

  ${media.lg`
    min-height: calc(100vh - 53px);
  `};
  @media (max-width: 992px) {
    margin-top: 8px;
  }
`;

const PaginationWrapper = styled(Row)`
  margin-top: auto;
  .ant-pagination {
    margin-top: 24px;
  }
`;

interface IStateProps {
  marketpage: ContainerState;
  category: Category;
  addedProducts: any;
  addingProducts: any;
  permissions: string[];
  breadCrumbDataMarkup: string;
}

interface IDispatchProps {
  onSearch: (data: any) => void;
  onToggleFavorite: (data: any) => void;
  setProductNote: (data: any) => void;
  gotoSignIn: () => void;
}

type Props = IStateProps & IDispatchProps & RouteComponentProps;

const MarketPage: FC<Props> = (props) => {
  const {
    marketpage: { result, search, noteItemLoading },
    permissions,
    category,
    addedProducts,
    addingProducts,
    onSearch,
    onToggleFavorite,
    setProductNote,
    gotoSignIn,
    breadCrumbDataMarkup,
    location,
  } = props;
  const loggedIn = !!localStorage.getItem('token');
  const { localLang, alternateLang } = result;
  const [previewProduct, setPreviewProduct] = useState<any>();
  const [isOpenNoteModal, setIsOpenNoteModal] = useState<boolean>(false);
  const onChangePage = useCallback(
    (page: number) => {
      onSearch({
        search: {
          ...search,
          pagination: {
            page: page !== 1 ? page : undefined,
          },
        },
        isFromCategory: !location.pathname.startsWith('/market'),
      });
    },
    [search, onSearch, location.pathname],
  );

  const onClickTogglingFavorite = useCallback(
    (product) => {
      onToggleFavorite({ product: product });
    },
    [onToggleFavorite],
  );

  const onClickCloseNoteModal = useCallback(() => {
    setPreviewProduct(undefined);
    setIsOpenNoteModal(false);
  }, [setPreviewProduct, setIsOpenNoteModal]);

  const onSubmitAddingNote = useCallback(
    (note) => {
      setProductNote({ productId: previewProduct.id, note: note });
      onClickCloseNoteModal();
    },
    [previewProduct, setProductNote, onClickCloseNoteModal],
  );

  const onClickDeleteNote = useCallback(
    (product) => {
      setProductNote({ productId: product.id, note: '' });
    },
    [setProductNote],
  );

  const onClickOpenNoteModal = useCallback(
    (product) => {
      if (loggedIn) {
        setPreviewProduct(product);
        setIsOpenNoteModal(true);
      } else {
        gotoSignIn();
      }
    },
    [setIsOpenNoteModal, setPreviewProduct, loggedIn],
  );

  const trackingFrom = useMemo<ITrackingFrom>(() => {
    switch (true) {
      case !!search.filter?.query: {
        return { productImpression: TrackingType.SearchResult, addToCart: TrackingAddToCartAddFromType.SearchResult };
      }
      case !!search.filter?.tags: {
        if (isRecommendationTag(search.filter?.tags)) {
          return {
            productImpression: TrackingType.CollectionRecommendation,
            addToCart: TrackingAddToCartAddFromType.CollectionRecommendation,
          };
        }
        return {
          productImpression: TrackingType.CollectionTag,
          addToCart: TrackingAddToCartAddFromType.CollectionTag,
        };
      }
      case !!search.filter?.categoryName?.includes('/'): {
        return {
          productImpression: TrackingType.CollectionSubCategory,
          addToCart: TrackingAddToCartAddFromType.CollectionSubCategory,
        };
      }
      case !!search.filter?.categoryName: {
        return {
          productImpression: TrackingType.CollectionCategory,
          addToCart: TrackingAddToCartAddFromType.CollectionCategory,
        };
      }
      default: {
        return { productImpression: TrackingType.AllProduct, addToCart: TrackingAddToCartAddFromType.AllProduct };
      }
    }
  }, [search.filter]);

  const meatadataKey = useMemo<IMetadataKeyData>(() => {
    if (search?.filter?.tags) {
      return {
        data: search?.filter?.tags?.[0],
        type: 'tags',
      };
    }
    if (category?.name) {
      return {
        data: category.name,
        type: 'category',
      };
    }
    if (search?.filter?.query) {
      return {
        data: 'search',
        type: 'search',
        querySearch: search?.filter?.query,
      };
    }
    if (isEmpty(search?.filter) || isEmpty(search?.sort)) {
      return {
        data: 'market',
        type: 'page',
      };
    }
    return { type: 'empty', data: '' };
  }, [search, category]);

  const isProductSearch =
    meatadataKey.data === 'search' && !keywordData.keyword.some((key) => key === meatadataKey.querySearch);

  return (
    <>
      <ProductListContainer>
        <RenderMetadataMarket
          noIndex={isProductSearch || !result?.data?.length}
          metadataKey={meatadataKey}
          page={search?.pagination?.page}
          breadCrumbData={breadCrumbDataMarkup}
          localLang={localLang}
          alternateLang={alternateLang}
        />
        <PageHeader asComponent="h1">{renderCategoryName(category, search)}</PageHeader>
        <ProductListRow gutter={[24, 24]}>
          {result.data.map((product, index) => (
            <Col md={8} xl={6} key={product?.id}>
              <MarketItem
                listName="Market Product List"
                loggedIn={loggedIn}
                gotoSignIn={gotoSignIn}
                product={product}
                loading={product?.loading}
                favorite={product?.isFavorite}
                note={product?.note}
                maxQuantity={100}
                added={addedProducts[product.id]}
                adding={addingProducts[product.id]}
                position={index}
                permissions={permissions}
                onToggleFavorite={() => onClickTogglingFavorite(product)}
                onOpenNote={() => onClickOpenNoteModal(product)}
                onDeleteNote={() => onClickDeleteNote(product)}
                impressionProperties={{
                  type: trackingFrom.productImpression,
                  tag:
                    trackingFrom.productImpression === TrackingType.CollectionTag
                      ? search.filter?.tags?.[0]
                      : undefined,
                  keyword:
                    trackingFrom.productImpression === TrackingType.SearchResult ? search.filter?.query : undefined,
                }}
                addToCartProperties={{
                  addFrom: trackingFrom.addToCart,
                  tag:
                    trackingFrom.addToCart === TrackingAddToCartAddFromType.CollectionTag
                      ? search.filter?.tags?.[0]
                      : undefined,
                }}
              />
            </Col>
          ))}
        </ProductListRow>
        {result.data.length ? (
          <PaginationWrapper>
            <Col span={24}>
              <Pagination
                itemRender={(page, type, origin) => {
                  if (type === 'next' || type === 'prev' || type === 'jump-prev' || type === 'jump-next') return origin;
                  const tags = search?.filter?.tags?.toString();

                  /* Create the category segment's name with hyphen instead of encode URL */
                  const category = generateSlugFromQueryName(search?.filter?.categoryName ?? '', localLang);
                  const query = search?.filter?.query?.toString();

                  /* Check if current pagination URL is come from category or market search */
                  const isFromCategory = !location.pathname.startsWith('/market');
                  return (
                    <PaginationLink
                      page={page}
                      isFromCategory={isFromCategory}
                      category={category}
                      tags={tags}
                      query={query}
                      lang={localLang}
                    />
                  );
                }}
                onChange={onChangePage}
                total={result.totalResults}
                pageSize={search.pagination.size || 40}
                current={search.pagination.page || 1}
                defaultCurrent={search.pagination.page || 1}
                showSizeChanger={false}
              />
            </Col>
          </PaginationWrapper>
        ) : (
          <FlexContainer>
            <SearchOutlined style={{ fontSize: '30px' }} />
            <Title>
              <FormattedMessage {...messages.notFound} />
            </Title>
            <Content>
              <FormattedMessage {...messages.notFoundDescription} />
            </Content>
          </FlexContainer>
        )}
        <ModalNote
          headerLabel={previewProduct && previewProduct.name}
          isOpen={isOpenNoteModal}
          confirmLoading={noteItemLoading}
          onSubmit={onSubmitAddingNote}
          onClose={onClickCloseNoteModal}
          note={previewProduct ? previewProduct.note : ''}
        />
      </ProductListContainer>
    </>
  );
};

const mapStateToProps = createStructuredSelector<RootState, IStateProps>({
  marketpage: selectMarketPage(),
  category: selectSelectedCategory(),
  addedProducts: selectAddedProducts(),
  addingProducts: selectAddingProducts(),
  permissions: selectUserPermissions(),
  breadCrumbDataMarkup: selectBreadCrumbDataMarkup(),
});

function mapDispatchToProps(dispatch: Dispatch): IDispatchProps {
  return {
    onSearch: (data) => dispatch(applySearch(data)),
    onToggleFavorite: (data) => dispatch(setFavorite(data)),
    setProductNote: (data: any) => dispatch(setProductNote(data)),
    gotoSignIn: () => dispatch(push('/signin')),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<Props>({ key: 'marketPage', reducer: reducer });
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<Props>({ key: 'marketPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(MarketPage);
