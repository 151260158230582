/*
 *
 * MarketPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/MarketPage/DEFAULT_ACTION',
  APPLY_SEARCH = 'app/MarketPage/APPLY_SEARCH',
  SET_SEARCH = 'app/MarketPage/SET_SEARCH',
  FETCH_PRODUCTS_REQUEST = 'app/MarketPage/FETCH_PRODUCTS_REQUEST',
  FETCH_PRODUCTS_SUCCESS = 'app/MarketPage/FETCH_PRODUCTS_SUCCESS',
  FETCH_PRODUCTS_FAILURE = 'app/MarketPage/FETCH_PRODUCTS_FAILURE',
}

export { ActionTypes };
