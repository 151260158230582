import METADATA from 'containers/MarketPage/metadata';
import React, { FC, memo, useMemo } from 'react';
import Helmet from 'react-helmet';
import translations from 'translations';
import messages from 'utils/messages';
import renderWithWebDomain from 'utils/renderWithWebDomain';
import { generateSlugFromQueryName } from 'utils/urls/categoriesPathSegment';

export interface IMetadataKeyData {
  data: string;
  type: 'category' | 'tags' | 'page' | 'empty' | 'search';
  querySearch?: string;
}

interface RenderMetadataMarketProps {
  metadataKey: IMetadataKeyData;
  page?: number;
  noIndex?: boolean;
  breadCrumbData?: string;
  localLang: string;
  alternateLang: string;
}

const RenderMetadataMarket: FC<RenderMetadataMarketProps> = (props) => {
  const {
    metadataKey: { data, type, querySearch },
    page,
    noIndex,
    breadCrumbData,
    localLang,
    alternateLang,
  } = props;

  const marketPageUrls = useMemo(() => {
    const pageQuery = Number(page) > 1 ? `page=${page}` : '';
    const queryResult = !!pageQuery ? `?${pageQuery}` : '';

    switch (type) {
      case 'category':
        return {
          validURL: `/${generateSlugFromQueryName(data, localLang)}${queryResult}`,
          alternateLangURL: `/${generateSlugFromQueryName(data, alternateLang)}${queryResult}`,
        };
      case 'tags':
        return {
          validURL: `/${data.split(' ').join('-')}${queryResult}`,
        };
      case 'page':
        return {
          validURL: `/market${queryResult}`,
        };
      case 'search':
        return {
          validURL: `/market?q=${encodeURIComponent(querySearch ?? '')}`,
        };
      default:
        return '';
    }
  }, [page, type, querySearch, data, localLang, alternateLang]);

  const validURL = marketPageUrls && marketPageUrls?.validURL;
  const alternateLangURL = marketPageUrls && marketPageUrls?.alternateLangURL;
  const isOtherTags = type === 'tags' && METADATA[data] === undefined;
  const metadata = METADATA[data] ? METADATA[data][localLang] : isOtherTags ? METADATA.DEFAULT[localLang] : undefined;

  if (!data || !metadata) {
    return (
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <title>
        {data === 'search'
          ? metadata.generateTitle?.(querySearch ?? '')
          : isOtherTags
            ? metadata.generateTitle?.(translations(messages[`${data}`]))
            : metadata.title}
      </title>
      <meta property="og:url" content={renderWithWebDomain(process.env.APP_ENV, validURL)} />
      <link rel="canonical" href={renderWithWebDomain(process.env.APP_ENV, validURL)} />
      <link rel="alternate" href={renderWithWebDomain(process.env.APP_ENV, validURL)} />
      <meta
        property="og:title"
        content={
          data === 'search'
            ? metadata.generateTitle?.(querySearch ?? '')
            : isOtherTags
              ? metadata.generateTitle?.(translations(messages[`${data}`]))
              : metadata.title
        }
      />

      {type === 'category' && (
        <link rel="alternate" hrefLang={localLang} href={renderWithWebDomain(process.env.APP_ENV, validURL)} />
      )}
      {type === 'category' && (
        <link
          rel="alternate"
          hrefLang={alternateLang}
          href={renderWithWebDomain(process.env.APP_ENV, alternateLangURL)}
        />
      )}
      {type === 'category' && (
        <link rel="alternate" hrefLang="x-default" href={renderWithWebDomain(process.env.APP_ENV, validURL)} />
      )}
      <meta
        name="description"
        content={
          data === 'search'
            ? metadata.generateDescription?.(querySearch ?? '')
            : isOtherTags
              ? metadata.generateDescription?.(translations(messages[`${data}`]))
              : metadata.description
        }
      />
      <meta
        name="og:description"
        content={
          data === 'search'
            ? metadata.generateDescription?.(querySearch ?? '')
            : isOtherTags
              ? metadata.generateDescription?.(translations(messages[`${data}`]))
              : metadata.description
        }
      />
      <meta property="og:image:url" content={renderWithWebDomain(process.env.APP_ENV, metadata?.image?.url)} />
      <meta property="og:image:width" content={metadata?.image?.height} />
      <meta property="og:image:height" content={metadata?.image?.width} />
      <meta property="og:image:secure_url" content={renderWithWebDomain(process.env.APP_ENV, metadata?.image?.url)} />
      <meta property="og:image:alt" content={metadata.title} />
      {data === 'search' && (
        <link
          rel="alternate"
          hrefLang={localLang}
          href={`${renderWithWebDomain(process.env.APP_ENV, validURL)}${localLang === 'en' ? '&lang=en' : ''}`}
        />
      )}
      {data === 'search' && (
        <link
          rel="alternate"
          hrefLang={alternateLang}
          href={`${renderWithWebDomain(process.env.APP_ENV, validURL)}${alternateLang === 'en' ? '&lang=en' : ''}`}
        />
      )}
      {data === 'search' && (
        <link rel="alternate" hrefLang="x-default" href={renderWithWebDomain(process.env.APP_ENV, validURL)} />
      )}

      {noIndex && <meta name="robots" content="noindex, nofollow" />}
      <script type="application/ld+json">{breadCrumbData ?? ''}</script>
    </Helmet>
  );
};

export default memo(RenderMetadataMarket);
