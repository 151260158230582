import { push } from 'connected-react-router';
import { makeSelectLocation } from 'containers/MainLayout/selectors';
import { call, fork, put, select, take } from 'redux-saga/effects';
import { Pagination, ProductSortField, QueryProductSearchArgs, SortDirection } from 'types/schema';
import { getProducts, getRecommendationItems } from 'utils/apollo';
import * as qs from 'utils/queryString';
import { isRecommendationTag } from 'utils/utilities';
import { createSearchQueryfromSlug, getPathSegmentFromSlug } from 'utils/getPathSegmentFromSlug';
import { pathnameChecker } from 'utils/url';
import { products as productsActions } from './actions';
import { ActionTypes } from './constants';
import { LANGUAGES } from 'utils/constants';

function* searchFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.APPLY_SEARCH);
    if (!payload.isFromCategory) {
      yield put(push({ search: qs.stringify(payload.search) }));
    }
    yield put({ type: ActionTypes.SET_SEARCH, payload: payload.search });
  }
}

function* initData(searchValue?: QueryProductSearchArgs) {
  yield put(productsActions.request());
  const localLang = window.localStorage.getItem('lang') || 'vi';
  const alternateLang = localLang === LANGUAGES.Vi ? LANGUAGES.Alternate || 'en' : LANGUAGES.Vi;
  const search: QueryProductSearchArgs = {
    sort: [],
    filter: {},
    ...searchValue,
    pagination: { page: searchValue?.pagination?.page ?? 0, size: 40 },
  };
  const requestParams: QueryProductSearchArgs = { ...search };

  if (!search.sort.length) {
    requestParams.sort = [{ field: ProductSortField.Name, order: SortDirection.Asc }];
  }

  const requestPage = requestParams.pagination.page;
  requestParams.pagination = {
    ...requestParams.pagination,
    page: requestPage ? requestPage - 1 : 0,
  };
  yield put({ type: ActionTypes.SET_SEARCH, payload: search });

  const response = yield call(getProducts, requestParams);

  if (!response.errors) {
    yield put(
      productsActions.success({
        result: {
          ...response,
          localLang: localLang,
          alternateLang: alternateLang,
        },
      }),
    );
    window.localStorage.setItem('lang', localLang as string);
  } else {
    yield put(productsActions.failure(response.errors));
  }
}

function* recomendationData(searchValue?: QueryProductSearchArgs) {
  yield put(productsActions.request());
  const pagination: Pagination = {
    size: searchValue?.pagination?.size || 40,
    page: searchValue?.pagination?.page || 1,
  };

  yield put({
    type: ActionTypes.SET_SEARCH,
    payload: { pagination: pagination, filter: { tags: ['RECOMMENDED_FOR_YOU'] } },
  });

  const response = yield call(getRecommendationItems, { pagination: { ...pagination, page: pagination.page - 1 } });
  if (!response.errors) {
    yield put(productsActions.success({ result: response }));
  } else {
    yield put(productsActions.failure(response.errors));
  }
}

export default function* marketPageSaga() {
  const location = yield select(makeSelectLocation());
  if (pathnameChecker.isProductPage(location.pathname)) {
    yield put(push('/not-found'));
  } else if (location.pathname.endsWith('/')) {
    const pathWithoutSlash = location.pathname.replace(/\/+$/g, '');
    yield put(push(`${pathWithoutSlash}`));
  }
  /* Transform the pathname with hyphen into seperate segment's slug value */
  const segment = getPathSegmentFromSlug(location);

  /* create a query string that can be parsed into an qs object. */
  const searchQuery = createSearchQueryfromSlug(location, segment);
  const searchValue: QueryProductSearchArgs | undefined = searchQuery.length > 1 ? qs.parse(searchQuery) : undefined;

  const isRecommended = isRecommendationTag(segment.tags);
  if (!isRecommended) {
    yield fork(initData, searchValue);
  } else {
    yield fork(recomendationData, searchValue);
  }

  yield fork(searchFlow);
}
